.faq__services {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
    width: 100%;
}

.faq__services_title {
    font-size: 3rem;
    color: var(--yellow);
    margin: 4rem 0;
}

.faq__services_container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
}

@media (max-width:700px) {
    .faq__services_title {
        font-size: 1.5rem;
        text-align: center;
    }
}