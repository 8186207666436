.home__services_box {
  width: 30%;
  padding: 2rem;
  border-radius: 0.5rem;
  /* box-shadow: var(--box-shadow); */
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: var(--yellow);
}
.home__services_box_icon {
  font-size: 2rem;
  padding-bottom: 3rem;
  color: #fff;
  display: flex;
  justify-content: flex-end;
}
.home__services_box h3 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: #fff;
}
.home__services_box p {
  font-size: 0.8rem;
  line-height: 1.8;
  color: #fff;
}
.home__services_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: var(--box-shadow);
  background: var(--bg-color);
  z-index: -1;
  clip-path: circle(5rem at 5% 0%);
  transition: 0.3s linear;
}
.home__services_box:hover::before {
  clip-path: circle(100%);
}
.home__services_box:hover > * {
  color: var(--black);
}

@media only screen and (max-width: 990px) and (min-width: 700px) {
    .home__services_box{
        width: 40%;
    }
}

@media (max-width: 700px) {
  .home__services_box {
    width: 90%;
  }
}
