.service__post{
    width: 630px;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    background-color: var(--bg-color);
}
.service__post_img{
    width: 100%;
    transform: translate(-40px,-40px);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    background-color: var(--bg-color);
}
.service__post_img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.service__post_info{
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.service__post_category_date{
    margin-bottom: 15px;
    color: rgb(89, 89, 89);
    font-weight: 600;
    font-size: 1.2rem;
}
.service__post_title{
    color: var(--yellow);
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.service__post_desp{
    font-size: 0.8rem;
    color: var(--black);
}
.service__post_category_date,
.service__post_profile_more{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.service__post_profile{
    display: flex;
}
.service__post_profile_more{
    margin-top: 15px;
}
.service__post_profile_img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.service__post_profile_img img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.service__post_name{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: var(--yellow);
}
.service__post_more{
    width: 130px;
    height: 38px;
    border-radius: 5px;
    margin-top: 10px;
    color: var(--bg-color);
    background-color: var(--yellow);
    font-size: 20px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
}
@media screen and (max-width:720px){
    .service__post{
        flex-direction: column;
        width: 70%;
        padding: 30px;
    }
    .service__post_img{
        width: 100%;
        transform: translate(-0px,-50px);
    }
    .service__post_info {
        width: 100%;
    }
    .service__post_category_date,
    .service__post_profile_more{
        margin-top: 18px;
    }
}
@media screen and (max-width:530px){
    .service__post_info {
        transform: translate(0px, -10px);
    }
    .service__post_title {
        font-size: 1.4rem;
    }
    .service__post {
        padding: 20px;
        width: 90%;
    }
}